import i18n from '@/plugins/i18n';
import { isMinAge } from '@/utils/datetime';

const required = [
  (v) => !!v || i18n.t('validators.required'),
];

const hardRequired = [
  (v) => !!v?.trim() || i18n.t('validators.required'),
];

const boolean = [
  (v) => typeof v === 'boolean' || i18n.t('validators.required'),
];

const email = [
  (v) => !!v || i18n.t('validators.email.required'),
  (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || i18n.t('validators.email.valid'),
];

const password = [
  (v) => !!v || i18n.t('validators.password.required'),
  (v) => (v && v.length >= 6) || i18n.t('validators.password.length'),
  (v) => /(?=.*[A-Z])/.test(v) || i18n.t('validators.password.uppercase'),
  (v) => /(?=.*[A-Z])/.test(v) || i18n.t('validators.password.lowercase'),
  (v) => /(?=.*\d)/.test(v) || i18n.t('validators.password.number'),
  (v) => /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(v) || i18n.t('validators.password.symbol'),
];

const tos = [
  (v) => !!v || i18n.t('validators.tos.required'),
];

const alpha = [
  (v) => /^[\p{L}\p{M}\p{Zs}]+$/u.test(v) || i18n.t('validators.names.alpha'),
];

const name = [
  (v) => !!v || i18n.t('validators.names.required'),
  (v) => /(?!.*-$)^[\p{L}\p{M} -]+$/u.test(v) || i18n.t('validators.names.alpha'),
];

const street = [
  (v) => !!v || i18n.t('validators.street.required'),
  (v) => /^[\p{L}\p{M}\p{Zs}.-]*$/u.test(v) || i18n.t('validators.street.alpha'),
];

const city = [
  (v) => !!v || i18n.t('validators.city.required'),
  (v) => /^[\p{L}\p{M}\p{Zs}.-]*$/u.test(v) || i18n.t('validators.city.alpha'),
];

const zip = [
  (v) => !!v || i18n.t('validators.zip.required'),
  // (v) => (v && v.length === 5) || i18n.t('validators.zip.length'),
  // (v) => /^\d{5}$/i.test(v) || i18n.t('validators.zip.numeric'),
];

const birthDay = [
  (v) => !!v || i18n.t('validators.birthDay.required'),
  (v) => /^[0-9]{2}.[0-9]{2}.[0-9]{4}$/i.test(v) || i18n.t('validators.birthDay.format'),
  (v) => isMinAge(v, 18) || i18n.t('validators.birthDay.minAge'),
];

const alphanumeric = [
  (v) => !!v || i18n.t('validators.alphanumField.required'),
  (v) => /^[\p{L}\p{M}\p{Zs}\d+]+$/u.test(v) || i18n.t('validators.alphanumField.alphanum'),
];

const isUsCitizen = [
  (v) => (v === '0') || i18n.t('validators.isUsCitizen.required'),
];

const isPoliticallyExposed = [
  (v) => !!v || i18n.t('validators.isPoliticallyExposed.required'),
];

const fullname = [
  (v) => !!v || i18n.t('validators.names.required'),
  (v) => /^[\p{L}\p{M}\p{Zs}\d+]+$/u.test(v) || i18n.t('validators.names.alpha'),
];

const iban = [
  (v) => !!v || i18n.t('validators.iban.required'),
  (v) => /^[A-Z0-9 ]+$/i.test(v) || i18n.t('validators.iban.alphanum'),
  (v) => (v && v.length >= 16) || i18n.t('validators.iban.minlength'),
  (v) => (v && v.length <= 34) || i18n.t('validators.iban.maxlength'),
];

const bic = [
  (v) => /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/i.test(v) || i18n.t('validators.bic.invalid'),
];

const investorClassification = [
  (v) => ['Private', 'Professional'].includes(v) || i18n.t('validators.required'),
];

const numbers = [
  (v) => !!v || i18n.t('validators.generic.required'),
  (v) => /^[0-9]*$/i.test(v) || i18n.t('validators.generic.numbers'),
];

const textarea = [
  (v) => !!v || i18n.t('validators.generic.required'),
  (v) => /^(\S+)/i.test(v) || i18n.t('validators.generic.alphanumeric'),
];

const url = [
  (v) => !!v || i18n.t('validators.url.required'),
  (v) => /^[https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)]?$/i.test(v) || i18n.t('validators.url.valid'),
];

export {
  required,
  hardRequired,
  boolean,
  email,
  password,
  tos,
  alpha,
  name,
  street,
  city,
  zip,
  birthDay,
  numbers,
  textarea,
  alphanumeric,
  isUsCitizen,
  isPoliticallyExposed,
  fullname,
  iban,
  bic,
  investorClassification,
  url,
};
