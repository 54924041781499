const isMinAge = (date, minAge) => {
  if (!date) return true;
  const [day, month, year] = date.split('.');
  const today = new Date();
  const minDate = today.setFullYear(today.getFullYear() - minAge);
  return minDate >= new Date(`${year}-${month}-${day}`);
};

module.exports = {
  isMinAge,
};
